import { render, staticRenderFns } from "./HeaderApp.vue?vue&type=template&id=2d4a84c6&scoped=true&"
import script from "./HeaderApp.vue?vue&type=script&lang=js&"
export * from "./HeaderApp.vue?vue&type=script&lang=js&"
import style0 from "./HeaderApp.vue?vue&type=style&index=0&id=2d4a84c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d4a84c6",
  null
  
)

/* custom blocks */
import block0 from "@/locales/confirmation.json?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CUsers%5CLenovo%5CDesktop%5CUpdated%20%2B%20NewVue%5CTarificateur%5CAtlantis.Web%5CClientApp%5Csrc%5Ccomponents%5CHeaderApp.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports