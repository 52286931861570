<i18n src="@/locales/dateOfBirth.json"></i18n>

<template>
  <div class="birth">
    <div class="formRow" v-show="currentLocale == 'en'">
      <div class="dropdown month">
        <multiselect v-model="selectedMonthOption"
                     :options="availableMonthOptions"
                    label="name"
                   track-by="name"
                     :data-vv-name="monthValidationName"
                     :data-vv-scope="validationScope"
                     v-validate="'required|validMonthOption'"
                     :placeholder="$t('monthPlaceholder')"
                     :disabled="disabled">
          <template v-slot:noResult>
            {{ $t("noResult") }}
          </template>
        </multiselect>
      </div>
      <div class="dropdown day">
        <multiselect v-model="selectedDay"
                     :options="availableDays"
                     :data-vv-name="dayValidationName"
                     :data-vv-scope="validationScope"
                     v-validate="'required'"
                     :placeholder="$t('dayPlaceholder')"
                     :disabled="disabled">
          <template v-slot:noResult>
            {{ $t("noResult") }}
          </template>
        </multiselect>
      </div>
      <div class="dropdown year">
        <multiselect v-model="selectedYear"
                     :options="availableYears"
                     :data-vv-name="yearValidationName"
                     :data-vv-scope="validationScope"
                     v-validate="'required|validYear'"
                     :placeholder="$t('yearPlaceholder')"
                     :disabled="disabled">
          <template v-slot:noResult>
            {{ $t("noResult") }}
          </template>
        </multiselect>
      </div>
    </div>
    <div class="formRow" v-show="currentLocale == 'fr'">
      <div class="dropdown month">
        <multiselect v-model="selectedMonthOption"
                    :options="availableMonthOptions"
                    label="name"
                    track-by="name"
                     
                     :data-vv-name="monthValidationName"
                     :data-vv-scope="validationScope"
                     v-validate="'required|validMonthOption'"
                     :placeholder="$t('monthPlaceholder')"
                     :disabled="disabled">
          <template v-slot:noResult>
            {{ $t("noResult") }}
          </template>
        </multiselect>
      </div>
      
      <div class="dropdown day">
        <multiselect v-model="selectedDay"
                     :options="availableDays"
                     :data-vv-name="dayValidationName"
                     :data-vv-scope="validationScope"
                     v-validate="'required'"
                     :placeholder="$t('dayPlaceholder')"
                     :disabled="disabled">
          <template v-slot:noResult>
            {{ $t("noResult") }}
          </template>
        </multiselect>
      </div>
      
      <div class="dropdown year">
        <multiselect v-model="selectedYear"
                     :options="availableYears"
                     :data-vv-name="yearValidationName"
                     :data-vv-scope="validationScope"
                     v-validate="'required|validYear'"
                     :placeholder="$t('yearPlaceholder')"
                     :disabled="disabled">
          <template v-slot:noResult>
            {{ $t("noResult") }}
          </template>
        </multiselect>
      </div>
    </div>
    <div class="msgError"
         v-show="errors.has(yearValidationNameForScope) || errors.has(monthValidationNameForScope) || errors.has(dayValidationNameForScope) || errors.has(getValidationNameForScope)">{{ errors.first(yearValidationNameForScope) || errors.first(monthValidationNameForScope) || errors.first(dayValidationNameForScope) || errors.first(getValidationNameForScope) }}</div>
    <div class="val" v-bind:class="{ hasError: errors.has(getValidationNameForScope) }"></div>
  </div>
</template>

<script>
  import { formatHelper } from '../../scripts/helpers/formatHelper';
  import Multiselect from 'vue-multiselect';

  export default {
    components: {
      Multiselect
    },
    props: {
      specificDays: {
        type: Array,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false
      },
      specificYears: {
        type: Array,
        required: false
      },
      specificMonth: {
        type: Array,
        required: false
      },
      date: {
        type: Date,
        required: false
      },
      validationScope: {
        type: String,
        required: false
      },
      validationName: {
        type: String,
        required: true
      },
      currentLocale: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        defaultDate: formatHelper.formatDateToString(this.date),
        selectedYear: null,
        selectedMonth: null,
        selectedMonthOption: null,
        selectedDay: null,
        availableYears: [],
        availableMonths: [],
        availableMonthOptions: [],
        availableDays: []
      };
    },
    inject: {
      $validator: '$validator'
    },
    methods: {
      updateSelectedDate() {

          var month = this.selectedMonthOption?this.selectedMonthOption.value:0;

        if (this.selectedYear &&
          month &&
          Number(month) > 0 &&
          this.selectedDay) {
          this.selectedDate = new Date(Number(this.selectedYear),
            Number(month) - 1,
            Number(this.selectedDay));
        }
      },

      getAllMonthOptions(){
       
       var months=[];
       for(var i = 1;i<=12;i++){

        var monthName = this.$t('Month_' + i);
        months.push( { name: monthName, value: i });
       }
        return months;
      }
 
    },
    created() {
      this.$validator.attach({
        name: this.dayValidationName,
        rules: 'required|validDate',
        getter: () => {
          return {
            day: this.selectedDay,
            date: this.selectedDate
          };
        }
      });

      this.availableYears = [];
      this.availableMonths = [];
      this.availableDays = [];
      
      this.availableMonthOptions = this.getAllMonthOptions();

      var i;
      if (this.specificYears) {
        this.availableYears = this.availableYears.concat(this.specificYears);
      } else {
        for (i = 0; i < 125; i++) {
          this.availableYears.push(new Date().getFullYear() - i);
        }
      }

      if (this.specificMonth) {
        this.availableMonth = this.availableMonth.concat(this.specificMonth);
      } else {
        for (i = 0; i < 12; i++) {
          this.availableMonths.push(('0' + (i + 1)).slice(-2));
        }
      }

      if (this.specificDays) {
        this.availableDays = this.availableDays.concat(this.specificDays);
      } else {
        for (i = 0; i < 31; i++) {
          this.availableDays.push(('0' + (i + 1)).slice(-2));
        }
      }
    },
    beforeDestroy() {
      this.$validator.detach(this.dayValidationName);
    },
    computed: {
      getValidationName() {
        return 'dateOfBirth-' + this.validationName;
      },
      getValidationNameForScope() {
        if (this.validationScope) {
          return this.validationScope + '.' + this.getValidationName;
        }

        return this.getValidationName;
      },
      yearValidationName() {
        return this.getValidationName + '-year';
      },
      monthValidationName() {
        return this.getValidationName + '-month';
      },
      dayValidationName() {
        return this.getValidationName + '-day';
      },
      yearValidationNameForScope() {
        if (this.validationScope) {
          return this.validationScope + '.' + this.yearValidationName;
        }

        return this.yearValidationName;
      },
      monthValidationNameForScope() {
        if (this.validationScope) {
          return this.validationScope + '.' + this.monthValidationName;
        }

        return this.monthValidationName;
      },
      dayValidationNameForScope() {
        if (this.validationScope) {
          return this.validationScope + '.' + this.dayValidationName;
        }

        return this.dayValidationName;
      },
      selectedDate: {
        get() {



          if (this.date !== null) {

            this.selectedYear = this.date.getFullYear();
            this.selectedMonth = this.date.getMonth() + 1;
            this.selectedDay = this.date.getDate();

            this.selectedMonthOption = this.availableMonthOptions[this.date.getMonth()];
          }
          return this.date;
        },
        set(val) {
          this.$emit('update:date', val);
        }
      }
    },
    watch: {
      selectedYear: function () {
        this.updateSelectedDate();
      },
      // selectedMonth: function () {
      //   this.updateSelectedDate();
      // },
       selectedMonthOption: function () {
        this.updateSelectedDate();
      },
      selectedDay: function () {
        this.updateSelectedDate();
      },
      selectedDate: function () {
        this.$validator.validate(this.dayValidationName);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .formRow {
    display: flex;
    .day

  {
    width: 4.090625rem;
  }

  .month {
    width: 8.653125rem;
  }

  .year {
    width: 5.4375rem;
  }
  }
</style>
