<template>
  <button :class="classes" @click="click">
    <slot>[button]</slot>
  </button>
</template>

<script>

  export default {
    props: {
      disabled: {
        type: Boolean
      },
      classes: {}
    },
    methods: {
      click(event) {
        this.$emit('click', event);
      }
    }
  };
</script>

<style lang="scss" scoped>
  button {
    display: inline-block;
    min-width: 215px;
    padding: 0.90625rem 1.25rem;
    font-size: 1rem;
    line-height: 1.3125rem;
    background: #D6EBF7;
    font-weight: bold;
    color: gray;
    border: none;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    flex-shrink: 0;
    &:hover {
    background: #4982b8;
    color: white;
    }

    &.small {
      min-width: 160px;
    }

    &.marginLeft{
      margin-left: 1.5rem;
    }
  }
</style>
