<i18n src="@/locales/confirmation.json"></i18n>

<template>
  <div class="header">
    <div class="wrapper">
      <div class="logo">
        <a @click="navigateToHome()">
          <img src="../assets/Images/LOGO-AVANTYS-2021.png" width="239" alt />
        </a>
      </div>

      <div class="middleText">{{ $t("greetings") }} {{ BrokerFirstName}}!</div>

      <div class="rightContainer">
        <div class="left">
          <div class="name">{{ BrokerFullName }}</div>
          <a @click="logout" class="logout">{{ $t("logout") }}</a>
        </div>

        <div class="btnContainer">
          <base-button @click="newClient">{{ $t("newQuote") }}</base-button>
          <div class="qouteHistory">
            <a @click="navigateToHistory" class="logout">{{ $t("viewQuoteHistory") }}</a>
          </div>
        </div>

        <div class="right">
          <lang-select></lang-select>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import BaseButton from '@/components/BaseButton';
  import LangSelect from '@/components/LangSelect';
  import { localRouteHelper } from '@/localRouteHelper';

  export default {
    name: 'HeaderApp',
    components: {
      BaseButton,
      LangSelect
    },
    computed: {
      BrokerFullName() {
        return this.$store.state.app.brokerUserModel.firstName + ' ' + this.$store.state.app.brokerUserModel.lastName;
      },
      BrokerFirstName() {
        return this.$store.state.app.brokerUserModel.firstName;
      }
    },
    methods: {
      newClient() {

        var locale = localRouteHelper.getCurrentLocale();
      
        if(this.$route.name == locale+'.home'){

          if (confirm(this.$t('newClientConfirmation'))) {
            this.$router.go(0);
          }
        }else{
          this.navigateToHome();
        }
      },
      logout: function () {
        this.$store.dispatch('app/Logout');     
      },
      navigateToHome() {


        this.$router.push({
            name: localRouteHelper.getLocalRouteName('home')
          });

      },

      
      navigateToHistory() {
      
        

        this.$router.push({
            name: localRouteHelper.getLocalRouteName('pastPlans')
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .header {
    background: white;
    padding: 1.8125rem 0 1.5625rem;
    .wrapper

  {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    > div

  {
    width: inherit;

    &.middleText {
    text-align: center;
    font-size: 1.375rem;
    font-weight: 300;
    padding-top: 8px;
  }

  &.rightContainer {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    //align-items: center;
    .left

  {
    margin-right: 1.875rem;
    padding-top:4px;
    .name

  {
    font-weight: 300;
    margin-bottom: 0.25rem;
  }

  a {
    font-weight: 500;
  }

  }

  .btnContainer {
    margin-right: 0.5rem;
  }

  }
.qouteHistory{
   text-align:right;
  padding-top: 8px;
}

  &.logo{
    a {
     cursor: pointer;
    }
  }
  }
  }
  }
  .logo{  
    position: relative; 
    left: -40px;
  }
  @media screen and (max-width: 1500px) {
    .header {
      .wrapper

  {
    > div

  {
    width: inherit;
    margin-right: 0.75rem;
    &:last-child

  {
    margin-right: 0;
  }

  }
  }
  }
  }

  @media screen and (max-width: 1023px) {
    .header {
      .wrapper

  {
    .logo

  {
    max-width: 25%;
    img

  {
    max-width: 100%;
  }

  }
  }
  }
  }
</style>
