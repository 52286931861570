<template>
  <div class="langSelect">
    <div class="fakeSelect">
      <div class="value" v-click-outside="hide" @click="toggle">{{ $i18n.locale }}</div>
      <div class="valuesContainer" v-if="open">
        <div
          v-for="(lang, i) in langs"
          @click="setDropdown(lang, i + 1)"
          :class="{ selected : $i18n.locale == lang}"
        >{{ lang }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import routes from '../router/routes.js';
  import ClickOutside from 'vue-click-outside';

export default {
  name: 'LangSelect',
  data() {
    return {
      langs: ['fr', 'en'],
      open: false
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    hide() {
      this.open = false;
    },
    setDropdown(lang, i) {
      this.$i18n.locale = lang;
      this.$router.push({
            path: routes[i].path,
            params: { locale: this.$i18n.locale },
            query: this.$route.query
          });
      this.toggle();
    },
    close: function() {
      this.open = false;
    }
    },
    directives: {
      ClickOutside
    }
};
</script>

<style lang="scss" scoped>
select.hidden {
  display: none;
}

.fakeSelect {
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  user-select: none;

  &:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    font-weight: 700;
    top: 50%;
    right: 0;
    transform: translateY(-45%);
    pointer-events: none;
  }

  .value {
    padding: 0.875rem;
    min-width: 1.5rem;
  }

  .valuesContainer {
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(60%);
    background: #353731;
    border-radius: 5px;
    padding: 0.5rem 0;
    line-height: 1.6875;
    color: #9f9f9f;
    box-sizing: border-box;
    z-index: 99;

    &:before {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 4px;
      top: 0;
      right: 50%;
      transform: translate(50%, -40%) rotate(45deg);
      background: #353731;
    }

    > div {
      padding: 0 1rem;
      min-width: 3.375rem;
      box-sizing: border-box;
      transition: all 0.25s ease-in-out;
      text-align: center;

      &:hover {
        color: white;
      }

      &.selected {
        font-weight: bold;
        color: white;
      }
    }
  }
}
</style>
