class FormatHelper {
  formatCurrency(amount, isInCents, locale) {
    var money = amount;

    var options = {
      style: 'currency',
      currency: 'USD'
    };

    if (isInCents === true) {
      money = amount / 100;
    }

    money = Number(money.toFixed(2));

    if (locale === 'fr') {
      return money.toLocaleString('fr-CA', options);
    } else {
      return money.toLocaleString('en-CA', options);
    }
  }

  formatNumber(number, locale) {
    var formattedNumber = parseFloat(number).toFixed(2);

    if (locale === 'fr') {
      return formattedNumber.toLocaleString('fr-CA');
    } else {
      return formattedNumber.toLocaleString('en-CA');
    }
  }

  // Format YYYY-MM-DD
  formatDateToString(date) {
    if (!(date instanceof Date)) {
      return new Date(0, 0, 0);
    }

    return `${this.addLeadingZeroToYear(date.getUTCFullYear())}-${this.addLeadingZero(date.getUTCMonth() + 1)}-${this.addLeadingZero(date.getUTCDate())}`;
  }

  // Format YYYY-MM-DD HH:mm
  formatDatetimeToString(datetime) {
    if (!(datetime instanceof Date)) {
      return new Date(0, 0, 0, 0, 0);
    }

    var date = this.formatDateToString(datetime);

    return date + ' ' + `${this.addLeadingZero(datetime.getUTCHours())}:${this.addLeadingZero(datetime.getUTCMinutes())}`;
  }

  hasDateFormat(date) {
    if (!date && typeof date === 'string') {
      return false;
    } else {
      return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(date);
    }
  }

  // Vérifie si correspond au format YYYY-MM-DDTHH:mm
  hasDatetimeFormat(datetime) {
    if (!datetime && typeof datetime === 'string') {
      return false;
    } else {
      return /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]/.test(datetime);
    }
  }

  addLeadingZero(date) {
    return ('0' + date).slice(-2);
  }

  addLeadingZeroToYear(year) {
    return ('000' + year).slice(-4);
  }
}
export let formatHelper = new FormatHelper();
